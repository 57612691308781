export const lightTheme = {
    palette: {
        type: "light"
    }
}

export const darkTheme = {
    palette: {
        type: "dark"
    }
}
