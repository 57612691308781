import React from 'react';
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon';
import '../../styles/app/App.scss';
import {ReactComponent as LogoIcon} from '../../res/img/branding/logo/logo.svg';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import {lightTheme, darkTheme} from '../../styles/theme'

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      theme: darkTheme
    }

    this.toggleTheme = this.toggleTheme.bind(this);
  }

  toggleTheme() {
    this.setState({theme: (this.state.theme === darkTheme)?lightTheme:darkTheme});
  }

  render() {
    return (
      <ThemeProvider theme={createMuiTheme(this.state.theme)}>
      <CssBaseline />
        <div className="App">
          <header className="App-header">
            <div className="App-logo-wrapper" onClick={this.toggleTheme}>
              <SvgIcon component={LogoIcon} className="App-logo" viewBox="0 0 128 128" fontSize="inherit"/>
            </div>
            <div className="App-header-text">
              <Typography variant="h3">
                THE PALINDROME FOUNDATION
              </Typography>
              <Typography variant="h4">
                Soon™
              </Typography>
            </div>
          </header>
        </div>
        
      </ThemeProvider>
    );
  }
}

export default App;
